import dayjs from "dayjs";
import LinkComponent from "@/components/elements/LinkComponent";
import Button from "@/components/elements/Button";

import * as styles from "../../styles.module.scss";

interface DesktopMenuProps extends Pick<HeaderTemplate, "logo"> {
  title: string;
  documentUrl?: string;
  deadlineDate?: string;
  eventLink?: string;
  isFinished?: boolean;
  isSummary?: boolean;
  agenda?: string;
  renderBackLink: () => string;
}

export function DesktopMenu({
  logo,
  title,
  documentUrl,
  deadlineDate,
  isFinished,
  isSummary,
  agenda,
  eventLink,
  renderBackLink,
}: DesktopMenuProps) {
  const parsedDate = deadlineDate && dayjs(deadlineDate).format("DD MMMM YYYY");

  function handleAnchorClick(id: string) {
    const yOffset = -120;
    const element = document.getElementById(id);
    const y =
      element &&
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y || 0, behavior: "smooth" });
  }

  return (
    <div className={styles.desktopContainer}>
      <div className={styles.logo}>
        <LinkComponent url={renderBackLink()} type="internal">
          <img src="/svgs/arrow-left.svg" alt="arrow left" />
        </LinkComponent>
        <LinkComponent url="/" type="internal">
          <img src={logo?.filename} alt={logo?.alt} />{" "}
        </LinkComponent>
      </div>
      <p>{title}</p>
      <div>
        {!isFinished && parsedDate && <p>🎯 Deadline: {parsedDate}</p>}
        {documentUrl && !isFinished && (
          <Button variant="outlined">
            <LinkComponent url={documentUrl} type="external" with_icon>
              Application guidelines (pdf)
            </LinkComponent>
          </Button>
        )}
        {isSummary && (
          <button
            className={styles.eventButton}
            onClick={() => handleAnchorClick("summary")}
          >
            Summary
          </button>
        )}
        {agenda && (
          <button
            className={styles.eventButton}
            onClick={() => handleAnchorClick("agenda")}
          >
            {agenda || "Agenda"}
          </button>
        )}
        {eventLink && !isFinished && (
          <Button variant="outlined" className={styles.registerBtn}>
            <LinkComponent url={eventLink} type="external" with_icon>
              Register now
            </LinkComponent>
          </Button>
        )}
      </div>
    </div>
  );
}
