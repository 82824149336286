// extracted by mini-css-extract-plugin
export var desktopContainer = "styles-module--desktop-container--4996d";
export var eventButton = "styles-module--event-button--d514c";
export var hamburger = "styles-module--hamburger--a9351";
export var headerNavbar = "styles-module--header-navbar--4e1cb";
export var headerWrapper = "styles-module--header-wrapper--abbb8";
export var isActive = "styles-module--is-active--cb93b";
export var logo = "styles-module--logo--c964f";
export var mobileBackground = "styles-module--mobile-background--dd6df";
export var mobileMenu = "styles-module--mobile-menu--3b296";
export var mobileNavbar = "styles-module--mobile-navbar--301dc";
export var navigation = "styles-module--navigation--dd966";
export var registerBtn = "styles-module--register-btn--d3a7b";
export var scrolledNavbar = "styles-module--scrolled-navbar--195a9";
export var wrapper = "styles-module--wrapper--f5333";