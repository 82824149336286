import clsx from "clsx";
import { useEffect, useRef, useState } from "react";

import InfoBar from "@/components/layout/Header/InfoBar";
import { useLocation } from "@reach/router";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";

// eslint-disable-next-line import/order
import * as styles from "../styles.module.scss";

interface ArticleHeaderProps extends Pick<HeaderTemplate, "logo" | "info_bar"> {
  documentUrl?: string;
  eventLink?: string;
  isFinished?: boolean;
  agenda?: string;
  isSummary?: boolean;
  deadlineDate?: string;
  title: string;
}

export function ArticleHeader({
  info_bar,
  logo,
  documentUrl,
  title,
  isFinished,
  deadlineDate,
  isSummary,
  agenda,
  eventLink,
}: ArticleHeaderProps) {
  const [isNavbarScrolled, setIsNavbarScrolled] = useState(false);
  const nav = useRef<HTMLDivElement>(null);

  const location = useLocation();

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (nav.current) {
      if (position > 25) {
        setIsNavbarScrolled(true);
      } else {
        setIsNavbarScrolled(false);
      }
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderBackLink = () => {
    const backPages = ["research-grants", "events"];
    const previousPage = location.pathname.split("/")[1];
    const isBackPage = backPages.includes(previousPage);

    if (isBackPage) {
      return `/${backPages[backPages.indexOf(previousPage)]}/`;
    }
    return "/";
  };

  return (
    <header className={styles.headerWrapper}>
      <nav
        className={clsx(
          { [styles.scrolledNavbar]: isNavbarScrolled },
          styles.navigation,
          isNavbarScrolled ? "global-scrolled-navbar" : ""
        )}
        ref={nav}
      >
        <div className="container desktop">
          <DesktopMenu
            logo={logo}
            documentUrl={documentUrl}
            title={title}
            deadlineDate={deadlineDate}
            isFinished={isFinished}
            isSummary={isSummary}
            agenda={agenda}
            eventLink={eventLink}
            renderBackLink={renderBackLink}
          />
        </div>
        <MobileMenu
          logo={logo}
          eventLink={eventLink}
          renderBackLink={renderBackLink}
          isFinished={isFinished}
        />
      </nav>
      <InfoBar info_bar={info_bar} />
    </header>
  );
}
