import clsx from "clsx";
import { useEffect, useState } from "react";

import { graphql, useStaticQuery } from "gatsby";
import Button from "@/components/elements/Button";
import LinkComponent from "@/components/elements/LinkComponent";
import { MobileMenu as MobileMenuWithHamburger } from "@/components/layout/Header/MobileMenu/MobileMenu";
import * as styles from "../../styles.module.scss";

interface MobileMenuProps extends Pick<HeaderTemplate, "logo"> {
  eventLink?: string;
  renderBackLink: () => string;
  isFinished?: boolean;
}

export function MobileMenu({
  logo,
  eventLink,
  renderBackLink,
  isFinished,
}: MobileMenuProps) {
  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight * 0.01}px`);
      window.addEventListener("resize", appHeight);
    };
    appHeight();
    return () => window.removeEventListener("resize", appHeight);
  }, []);

  const { grant, header } = useStaticQuery(graphql`
    query ArticleMobileMenuAndArticleHeaderGrantPageQuery {
      grant: storyblokEntry(slug: { eq: "research-grants" }) {
        id
        full_slug
        content
      }
      header: storyblokEntry(field_component: { eq: "template_header" }) {
        content
      }
    }
  `);

  const grantData = grant
    ? JSON.parse(grant.content)?.body?.filter((body: Record<string, string>) =>
        body.component.includes("section_researchGrantsHero")
      )[0]?.open_grant
    : undefined;

  const parsedHeader = JSON.parse(header.content) as HeaderTemplate;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNestedNavVisible, setIsNestedNavVisible] = useState<
    boolean | string
  >(false);

  return (
    <div className={styles.mobileMenu}>
      <div className={clsx(styles.mobileNavbar, "mobile-nav-container")}>
        <div className={styles.logo}>
          <LinkComponent url={renderBackLink()} type="internal">
            <img src="/svgs/arrow-left.svg" alt="back" />
          </LinkComponent>
          <LinkComponent url="/" type="internal">
            <img src={logo?.filename} alt={logo?.alt} />
          </LinkComponent>
          {eventLink && !isFinished && (
            <Button variant="outlined" className={styles.registerBtn}>
              <LinkComponent url={eventLink} type="external" with_icon>
                Register now
              </LinkComponent>
            </Button>
          )}
        </div>
      </div>
      <div className={styles.hamburger}>
        <MobileMenuWithHamburger
          {...parsedHeader}
          isMobileMenuOpen={isMenuOpen}
          setIsMobileMenuOpen={setIsMenuOpen}
          isNestedNavVisible={isNestedNavVisible}
          setIsNestedNavVisible={setIsNestedNavVisible}
          currentGrant={grantData}
        />
      </div>
      <img className={styles.mobileBackground} src="/svgs/navbarMobileBg.svg" alt="background" data-svg="true" />
    </div>
  );
}
