import { graphql, PageProps } from "gatsby";

import React, { Suspense } from "react";
import { useStoryblok } from "@/base/lib/storyblok";

import ArticleLayout from "../layout/ArticleLayout";

type PageData = {
  story: StoryblokStory;
};

const EventPage = ({ data, location }: PageProps<PageData>) => {
  const { story } = data;

  const LazyEventHero = React.lazy(() => import("../sections/EventHero"));
  const LazyEventSectionsStoryblokComponent = React.lazy(
    () => import("@/base/utils/connectEventSectionsComponent")
  );

  const parsedStory = useStoryblok<EventPageTemplate>(story, location);

  const {
    content: {
      seo,
      event_title,
      event_date,
      event_categories,
      event_description,
      event_labels,
      event_is_finished,
      event_register_url,
      event_video_url,
      event_materials_url,
      event_thumbnail,
      event_video_type,
      event_video_time,
      body,
    },
  } = parsedStory;

  const checkIfComponentExists = (
    component:
      | "section_newsletter"
      | "section_eventSummary"
      | "section_eventAgenda"
  ) => Boolean(body.find((item) => item.component === component));

  return (
    <ArticleLayout
      title={event_title}
      seo={{ ...seo, og_image: seo.og_image }}
      type="article"
      isSummary={checkIfComponentExists("section_eventSummary")}
      agenda={
        checkIfComponentExists("section_eventAgenda") &&
        (body.find((item) => item.component === "section_eventAgenda")
          ?.heading ||
          "Agenda")
      }
      eventLink={event_register_url}
      isFinished={event_is_finished}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <LazyEventHero
          event_title={event_title}
          event_date={event_date}
          event_categories={event_categories}
          event_description={event_description}
          event_labels={event_labels}
          event_is_finished={event_is_finished}
          event_register_url={event_register_url}
          event_video_url={event_video_url}
          event_materials_url={event_materials_url}
          event_thumbnail={event_thumbnail}
          event_video_type={event_video_type}
          event_video_time={event_video_time}
        />

        {body.length > 0 &&
          body.map((comp) => (
            <LazyEventSectionsStoryblokComponent key={comp._uid} blok={comp} />
          ))}
      </Suspense>
    </ArticleLayout>
  );
};

export const pageQuery = graphql`
  query EventPageQuery($id: String!) {
    story: storyblokEntry(id: { eq: $id }) {
      id
      content
      full_slug
      slug
    }
  }
`;

export default EventPage;
